export async function useFetchUserNotifications() {
  const token = localStorage.getItem('token')
  const responseRef = ref<unknown>(null)

  try {
    const response: unknown = await useApiFetch('/api/user/notifications/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    responseRef.value = response
  } catch (error) {
    console.error('Error fetching credits:', error)
  }

  return { responseRef }
}
