<script setup lang="ts">
const fullPath = useRoute().fullPath
const baseUrl = useRequestURL().origin
const headerLink = `${baseUrl}${fullPath}`
const auth = useAuthStore()

type Notification = [{
  label: string,
  click: () => void
  class?: string
}]

const open = ref(false)
const notifications = ref<Notification[]>([])
const username = ref(auth.user?.first_name || '') // Default to first name if available else empty string, handle null case

/**
 * Handles the logout functionality.
 * 
 * @async
 * @function handlelogout
 * @returns {Promise<void>} - A promise that resolves when the logout process is complete.
 */
async function handlelogout() {
  const auth = useAuthStore()
  try {
    await auth.logout() // Attempt to logout
    navigateTo('/')
  } catch (error) {
    console.error('Logout failed:', error)
  }
}

onMounted(async () => {
  await fetchUserNotifications() // Fetch user notifications on mount
});

onUnmounted(() => {
  notifications.value = [] // Clear notifications on unmount
});

// Fetch user notifications fuction
async function fetchUserNotifications() {
  try {
    const fetchedNotifications = await useFetchUserNotifications() || [];

    // Add clear all button if notifications exist
    if (fetchedNotifications.responseRef.value.length > 0) {
      notifications.value.push([{
        label: "Clear all",
        click: async () => {
          await useMarkAllUserNotificationsAsRead(); // Mark all notifications as read
          notifications.value = []; // Clear current notifications
          await fetchUserNotifications(); // Reload notifications
        },
        class: 'text-red-500 font-bold'
      }])
    }

    if (fetchedNotifications.responseRef.value.length > 0) {
      for (const notification of fetchedNotifications.responseRef.value) {
        notifications.value.push([{
          label: notification.label,
          click: () => {
            markUserNotificationAsRead(notification.id)
          }
        }])
      }
    }

  } catch (error) {
    console.error("Failed to fetch user profile:", error);
    notifications.value = [];  // Set to empty if fetch fails
  }
}

// Mark user notification as read function
async function markUserNotificationAsRead(notificationId: string) {
  try {
    await useMarkUserNotificationAsRead({ id: notificationId });
    notifications.value = [];  // Set to empty if fetch fails
    await fetchUserNotifications(); // Refetch notifications
  } catch (error) {
    console.error("Failed to mark user notification as read:", error);
  }
}
</script>

<template>
  <UHeader class="background" :to="headerLink">
    <template #logo>
      SYNERGOS
    </template>

    <template #right>
      <div class="mr-5 font-semibold">
        Welcome {{ username }}!
      </div>
      <UDropdown
v-model:open="open" :items="notifications" :ui="{ item: { disabled: 'cursor-text select-text' } }"
        :popper="{ offsetDistance: 0, placement: 'bottom-start' }" class="mr-5">
        <UButton
:trailing-icon="notifications.length > 0 ? 'i-heroicons-bell-alert' : 'i-heroicons-bell'"
          :color="notifications.length > 0 ? 'red' : 'gray'" />
      </UDropdown>
      <UButton
label="Logout" icon="i-heroicons-arrow-right-20-solid" trailing color="black" class="hidden lg:flex"
        @click="handlelogout" />
    </template>
  </UHeader>
</template>