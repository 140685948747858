export const useMarkUserNotificationAsRead = async (data: any) => {
  const token = localStorage.getItem('token')
  const responseRef = ref<any>(null)

  try {
    const response: any = await useApiFetch('/api/user/notifications/markasread', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
    responseRef.value = response
  } catch (error) {
    console.error('Error marking user notification as read:', error)
  }

  return { responseRef }
}
